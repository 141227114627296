.feature-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text inside the container */
}

.feature-content {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px;
}

.feature-content h1 {
  color: white;
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}

.feature-content h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-bottom: 1em;
  color: white;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow cards to wrap onto multiple lines */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 10px;
  gap: 20px; /* Add spacing between card containers */
}
/* 3d90b1 */
.card {
  background-color: #be4114;
  border-radius: 10px;
  text-align: center;
  color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 350px; /* Set a fixed width */
  height: 320px; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  display: flex; /* Use flexbox for inner content alignment */
  flex-direction: column; /* Stack content vertically */
  justify-content: space-between; /* Distribute space between items */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 80px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  margin: 20px auto; /* Center image horizontally and add vertical spacing */
}

.card h3 {
  margin: 10px 0;
  font-size: 1.5em;
}

.card p {
  font-size: 0.9em;
  margin: 0 20px; /* Add margin for better readability */
  flex-grow: 1; /* Allow paragraph to take available space */
  overflow-y: auto; /* Enable vertical scroll if content overflows */
}
