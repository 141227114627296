/* Center the container */
.dreamgirl-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Heading Style */
  .dreamgirl-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Button Styles */
  .dreamgirl-button {
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .dreamgirl-button-secondary {
    background-color: #6c757d;
  }
  
  /* Form Styles */
  .dreamgirl-form {
    margin-top: 20px;
    text-align: left;
  }
  
  .dreamgirl-label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .dreamgirl-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    color: #495057; /* Ensures text is visible */
    background-color: #f8f9fa; /* Light background for input fields */
  }
  
  /* Placeholder text color */
  .dreamgirl-input::placeholder {
    color: #6c757d; /* Light gray placeholder */
  }
  
  /* Submit Button Styles */
  .dreamgirl-submit-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dreamgirl-submit-button:hover {
    background-color: #218838; /* Darker shade on hover */
  }
  