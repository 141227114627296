#home {
  background: url("./assets/tauk_bg.png") no-repeat center center;
  background-size: cover;
  color: white;
}

/* App.css or similar */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
}

#root {
  height: 100vh; /* Full viewport height for the root element */
}

.container {
  display: flex;
  flex-direction: column;
  height: auto; /* Adjust height to fit content */
}

section {
  flex: 1; /* Each section takes up the full available height */
}
