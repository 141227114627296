.sticky-header {
  position: sticky;
  top: 0;
  background-color: black;
  color: white;
  padding: 10px 0;
  z-index: 1000;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between logo and nav items */
  width: 100%; /* Ensure nav spans the full width of the header */
}

.header-logo {
  width: 80px;
}

.list-component {
  display: flex;
  flex-grow: 1; /* Allow this container to grow and take available space */
  justify-content: center; /* Center the nav items within the remaining space */
  margin-left: -60px;
}

.sticky-header nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 60px; /* Space between list items */
}

.sticky-header nav ul li {
  margin: 0;
}

.sticky-header nav ul li a {
  color: #b8aba9;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: bold;
}

.sticky-header nav ul li.active a,
.sticky-header nav ul li a:hover {
  color: white;
}

.menu-container {
  display: none; /* Hide by default for larger screens */
}

.menu {
  display: none; /* Hide by default for larger screens */
}

@media (max-width: 768px) {
  .sticky-header nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    gap: 20px; /* Space between list items */
  }

  nav {
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
  }

  .list-component {
    display: none; /* Center the nav items within the remaining space */
  }

  .header-logo {
    width: 60px; /* Smaller logo on mobile */
  }

  .header-logo {
    width: 50px;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    width: 30px;
  }
  .menu-container {
    position: relative;
    display: block;
  }
  .menu {
    display: block;
    position: absolute;
    top: 40px; /* Adjust position to be below the menu icon */
    right: 0;
    background: black;
    color: white;
    border: 1px solid #ddd;
    z-index: 1000; /* Ensure menu is on top */
    width: 100px;
  }

  .menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .menu li {
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .menu li:first-child {
    margin-top: 10px;
  }

  .menu li:last-child {
    border-bottom: none;
  }

  .menu a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }

  .menu a:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}
