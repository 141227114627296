.price-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text inside the container */
}

.price-container h5 {
  font-size: 1em; /* Increase font size for headings */
  margin-bottom: 20px; /* Increase space below heading */
  margin-top: 20px; /* Increase space below heading */
  color: white;
}

.pricing-content {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 100px;
}

.pricing-content h1 {
  color: white;
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}

.pricing-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow cards to wrap onto multiple lines */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 10px;
  gap: 20px; /* Add spacing between card containers */
  flex-wrap: nowrap;
}

.pricing-grid img {
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  margin: 10px auto; /* Center image horizontally and add vertical spacing */
}

.pricing-card {
  background-color: #be4114;
  border-radius: 10px;
  padding: 60px 7px; /* Increase padding for larger content area */
  text-align: center;
  color: white;
  flex: 1;
  transition: transform 0.3s, box-shadow 0.3s;
  min-width: 250px; /* Ensure cards don’t get too narrow on smaller screens */
  width: 300px; /* Fixed width */
  height: 400px; /* Fixed height */
  overflow: hidden;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
  font-size: 2em; /* Increase font size for headings */
  margin-bottom: 20px; /* Increase space below heading */
}

.pricing-card p {
  font-size: 1.5em; /* Increase font size for price */
  margin-bottom: 25px; /* Increase space below price */
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
}

.pricing-card ul li {
  font-size: 0.9em;
  margin-bottom: 15px;
}

/* .pricing-card button {
  padding: 15px 30px;
  font-size: 1.1em; 
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

.pricing-card button:hover {
  background-color: #ff4500;
}

/* .recommended {
  background-color: gray;
  position: relative;
  padding-top: 30px;
} */

/* .recommended .recommended-tag {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff6347;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.8em;
  } */

/* .pricing-card .recommended-tag {
  position: absolute;
  top: 132px;
  right: -97px;
  background-color: #ff6347;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8em;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  white-space: nowrap;
} */

@media (max-width: 1008px) {
  .pricing-grid {
    flex-direction: column; /* Switch to column for screens 768px or smaller */
  }
}
