.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  text-align: center; /* Center text inside the container */
}

.home-content {
  max-width: 800px; /* Set a max-width for better readability */
  width: 100%;
  margin-top: 0px;
}

.home-content h1 {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}

.home-content h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-bottom: 1em;
  color: white;
}

.input-container {
  display: flex;
  flex-direction: row; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
  gap: 10px; /* Space between elements */
  padding: 20px;
  width: 100%;
  max-width: 600px; /* Limit the width for larger screens */
  margin: auto; /* Center horizontally */
  box-sizing: border-box;
}

.input-container select,
.input-container input,
.input-container button {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.input-container select {
  padding: 10px;
  font-size: 1rem; /* Relative font size */
  width: 100%; /* Full width of the container */
  max-width: 150px; /* Maximum width to prevent too large size */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: black;
  appearance: none;
  height: 36px;
}

.input-container input {
  padding: 12px;
  font-size: 1rem; /* Relative font size */
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Maximum width to prevent too large size */
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  margin-left: -2px;
}

.input-container button {
  padding: 13px;
  font-size: 1rem; /* Relative font size */
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Full width of the container */
  max-width: 200px; /* Maximum width to prevent too large size */
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .input-container {
    flex-direction: column; /* Stack vertically on smaller screens */
    gap: 15px; /* Increase space between elements */
  }

  .input-container select,
  .input-container input,
  .input-container button {
    font-size: 0.9rem; /* Smaller font size */
    width: 100%; /* Full width of the container */
    max-width: none; /* Remove max-width constraint */
  }
  .home-content h1 {
    font-size: 3.45rem;
  }
}

@media (max-width: 480px) {
  .input-container select,
  .input-container input,
  .input-container button {
    font-size: 0.8rem; /* Even smaller font size */
    padding: 8px; /* Smaller padding */
  }
}
