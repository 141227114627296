/* Footer.css */
.footer {
  background-color: #181818; /* Light background color */
  padding: 8px 20px;
  text-align: center;
  color: white; /* Light text color */
}

.contact-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-content h2 {
  font-size: 2em;
  margin-right: 50px;
}

.footer-content {
  max-width: 1200px;
  align-content: center;
}

.contact-container {
  display: flex;
  gap: 20px; /* Add space between items */
  flex-wrap: wrap; /* Allow items to wrap if needed */
}

.contact-item {
  font-size: 14px;
}

.contact-item strong {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .contact-item {
    font-size: 12px;
  }
}
