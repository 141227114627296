.demo-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text inside the container */
}

.demo-content {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  margin-top: 150px;
}

.demo-content h1 {
  color: white;
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
}

.video-container {
  width: 100%;
  position: relative;
  background: #000; /* Background color for the video container */
  max-width: 640px; /* Adjust max-width as needed */
  height: 480px; /* Fixed height, adjust as needed */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
